import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { useFlexSearch } from 'react-use-flexsearch';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon, library } from '@fortawesome/fontawesome-svg-core';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import Seo from '../components/seo';
import FancyTitle from '../components/fancy-title';
import LayoutConsumer from '../components/layout-context';
import BlogGridView from '../components/blog-grid-view';
import SearchIcon from '../assets/search-icon.svg';
import * as icons from '../icons/icon-pack';
import { handleize } from '../helpers/handleize';
import { useWindowSize } from '../components/layout';

// eslint-disable-next-line
import 'swiper/css/navigation';

function BlogOverviewTemplate({ data, pageContext }) {
  library.add({ ...icons });
  const windowSize = useWindowSize();

  const siteTitle = data.site.siteMetadata?.title || 'Title';
  const [posts] = React.useState(data?.allStoryblokEntry?.edges);

  const { index, store } = data.localSearchBlog;
  const [showSearchInput, setShowSearchInput] = React.useState(false);
  const searchPostCount = 9;
  const [searchQuery, setSearchQuery] = React.useState('');
  const results = useFlexSearch(searchQuery, index, store);
  const [showIndex, setShowIndex] = React.useState(0);
  const inputRef = React.useRef(null);

  const searchPosts = React.useMemo(
    () => (searchQuery && searchQuery.length > 2 ? results : []),
    [searchQuery, results],
  );

  const showMore = React.useMemo(
    () => searchPosts.length > (showIndex + 1) * searchPostCount,
    [searchPosts.length, showIndex],
  );

  const currentPage = parseInt(data?.allStoryblokEntry?.pageInfo?.currentPage);
  const [pageCount] = React.useState(parseInt(data?.allStoryblokEntry?.pageInfo?.pageCount));

  const { tag } = pageContext;
  let categories = data?.allStoryblokDatasourceEntry?.nodes;
  categories = [
    {
      name: 'Alle',
    },
    ...categories,
  ];

  const currentCategory = categories.find((category) => category?.value === tag || (tag === undefined && category.name === 'Alle'));
  let noindex = '';
  const isBrowser = typeof window !== 'undefined';

  if (!isBrowser) {
    return false;
  }

  if (window.location.pathname.includes(`/blog/${currentCategory.value}`)) {
    noindex = 'noindex';
  }

  return (
    <LayoutConsumer>
      {({ set }) => {
        set({ pageTitle: siteTitle, fullWidth: true });
        return (
          <div className="px-4 lg:px-16 pb-[100px] lg:pb-60 pt-20 lg:pt-36">
            <Seo
              title="Der Shopify & Marketing Blog mit News vom Experten"
              description="Der Blog über Shopify & Shopify Plus in Deutschland. Regelmäßige E-Commerce-News, Marketing-Tipps & Updates für Ihren Erfolg im Onlinehandel."
              noindex={noindex}
            />
            <div>
              <FancyTitle
                text="Marketing-Tipps und Shopify News im Blog"
                className=" text-left"
                tag="h1"
              />
              <p className="lg:text-xl">Wir veröffentlichen in diesem Shopify Blog regelmäßig Blogbeiträge zu Shopify News, Updates und Tipps sowie wichtige Marketing-Informationen und Hacks, um Ihren Erfolg im E-Commerce zu steigern.</p>

              <div className="mt-[60px] md:mt-20 mx-auto flex gap-4 md:gap-0 md:min-h-[40px] flex-wrap md:flex-nowrap">
                <div className="flex flex-row w-full md:w-1/2">
                  {currentCategory && (
                    <div className="text-sm md:text-base block whitespace-nowrap px-4 py-2 rounded bg-latori-green-dark text-white w-fit md:ml-0 mr-2">{currentCategory.name}</div>
                  )}
                  <Swiper
                    className="cursor-grab swiper-blog"
                    modules={[Navigation]}
                    slidesPerView="auto"
                    spaceBetween={10}
                    // slidesOffsetAfter={16}
                    // slidesOffsetBefore={32}
                    navigation
                    breakpoints={{
                      1024: {
                        slidesOffsetAfter: 32,
                        slidesOffsetBefore: 0,
                      },
                    }}
                  >
                    {categories
                      .filter((category) => (category.name !== currentCategory.name))
                      .map((category) => (
                        <SwiperSlide
                          key={`categories-${category.name}`}
                          className="w-auto"
                          style={{ width: 'auto' }}
                        >
                          <Link to={category.name !== 'Alle' ? `/blog/${category.value}` : '/blog'} className={clsx('text-sm md:text-base block whitespace-nowrap px-4 py-2 rounded bg-background-light dark:bg-[#212121] hover:bg-latori-green-dark hover:text-white', category?.value === currentCategory?.value ? '!bg-latori-green-dark text-white' : '')}>{category.name}</Link>
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </div>

                <div className="flex md:ml-[32px] mt-5 md:mt-0 w-full">
                  <div className={
                    clsx(
                      'transition-all duration-1000 overflow-hidden',
                      windowSize.width < 768 ? 'w-full' : 'w-0',
                      showSearchInput ? 'w-full' : 'w-0',
                    )
                  }
                  >
                    <input
                      ref={inputRef}
                      value={searchQuery}
                      onInput={(e) => setSearchQuery(e.target.value)}
                      type="text"
                      id="search"
                      placeholder="Suchen"
                      className="text-black border-t-none border-l-none border-r-none bg-white text-xl md:text-2xl !outline-none max-w-full w-full border-b-2 border-solid border-black"
                    />
                  </div>
                  <button
                    onClick={() => {
                      if (!showSearchInput) {
                        inputRef.current.focus();
                      } else {
                        setSearchQuery('');
                      }
                      if (windowSize.width < 768) return;
                      setShowSearchInput(!showSearchInput);
                    }}
                    type="button"
                    className={
                      clsx(showSearchInput ? 'pl-4' : '')
                    }
                  >
                    {!showSearchInput && (
                      <SearchIcon className="h-6 w-8 text-black" fill="#000000" />
                    )}
                    {showSearchInput && (
                      <FontAwesomeIcon
                        icon={icon({ prefix: 'far', iconName: 'xmark' })}
                        size="2x"
                        color="black"
                      />
                    )}
                  </button>
                </div>
              </div>

              {searchQuery && searchQuery.length > 0 ? (
                <>
                  <div className="flex justify-center opacity-50 mt-12">
                    {searchQuery.length < 3 && (
                      <p>Mindestlänge 3</p>
                    )}
                    {searchPosts.length === 0 && searchQuery.length > 2 && (
                      <p>Kein Ergebnis. Versuchen Sie, etwas anderes zu suchen</p>
                    )}
                  </div>
                  <div className="mt-[60px] lg:mt-20 grid grid-cols-1 xl:grid-cols-3 md:grid-cols-2 gap-2 md:gap-4">
                    {searchPosts.slice(0, ((showIndex + 1) * searchPostCount)).map((post) => {
                      const title = post.name || post.slug;
                      const image = post.hero;
                      return (
                        <Link
                          to={`/${post.full_slug}`}
                          className="mx-auto aspect-[134/85] w-full relative group overflow-hidden"
                          key={title}
                        >
                          <article
                            itemScope
                            itemType="http://schema.org/Article"
                            className="bg-slate-100 dark:bg-latori-green-dark shadow-lg h-full"
                          >
                            {image && (
                            <img
                              alt={title}
                              src={image}
                              loading="lazy"
                              className="object-cover w-full h-full absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
                            />
                            )}
                            <div className="bg-latori-green-dark opacity-90 w-full h-full absolute bottom-0 left-0 transition-[height] group-hover:h-[0%]" />
                            <div className="w-full h-full absolute top-0 left-0 flex flex-col justify-center items-start px-4 group-hover:opacity-0 transition-opacity text-white">
                              <h3 className="text-2xl" itemProp="headline">
                                {title}
                              </h3>
                            </div>
                          </article>
                        </Link>
                      );
                    })}
                  </div>
                  {showMore && (
                    <div className="flex justify-center items-center">
                      <button
                        type="button"
                        className="button-primary inline-block mt-12"
                        onClick={() => {
                          setShowIndex(showIndex + 1);
                        }}
                      >
                        MEHR
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <BlogGridView posts={posts} />

                  {pageCount > 1 && (
                  <div className="px-6 md:px-8 mt-[100px] md:text-center gap-4">
                    {
                      currentPage > 1 && (
                        <Link to={currentPage - 1 === 1 ? `/blog${tag ? `/${handleize(tag)}` : ''}` : `/blog${tag ? `/${handleize(tag)}` : ''}/page/${currentPage - 1}`} className="inline-block">&lt; PREV</Link>
                      )
                    }
                    {Array(pageCount).fill(0).map((_, i) => {
                      const _page = i + 1;
                      return (
                        <Link
                          key={`page-l-${_page}`}
                          to={_page === 1 ? `/blog${tag ? `/${handleize(tag)}` : ''}` : `/blog${tag ? `/${handleize(tag)}` : ''}/page/${_page}`}
                          className={`w-[42px] h-[42px] rounded-full inline-flex justify-center items-center ${_page === currentPage ? 'bg-latori-green-dark text-white' : ''}`}
                        >
                          {_page}
                        </Link>
                      );
                    })}
                    {
                      pageCount > currentPage && (
                        <Link to={currentPage + 1 === 1 ? `/blog${tag ? `/${handleize(tag)}` : ''}` : `/blog${tag ? `/${handleize(tag)}` : ''}/page/${currentPage + 1}`} className="inline-block">NEXT &gt;</Link>
                      )
                    }
                  </div>
                  )}
                </>
              )}
            </div>
          </div>
        );
      }}
    </LayoutConsumer>
  );
}

export default BlogOverviewTemplate;

export const pageQuery = graphql`
  query BlogOverviewQuery (
    $skip: Int = 0
    $limit: Int = 10
    $tag: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    localSearchBlog {
      index
      store
    }
    allStoryblokDatasourceEntry(filter: {data_source: {eq: "categories"}}) {
      nodes {
        name
        value
      }
    }
    allStoryblokEntry(
      filter: { field_component: { eq: "blogpost" }, lang: {eq: "default"}, field_category_string: {eq: $tag }}
      sort: { fields: first_published_at, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          name
          slug
          lang
          content
          field_component
          first_published_at
          full_slug
          uuid
        }
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
  }
`;
